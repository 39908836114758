
////////////////////////////////////////////////////////////////////////////////

EVERYTHING

*
  font-family: arial, sans-serif
  box-sizing: border-box
  padding: 0
  margin: 0


////////////////////////////////////////////////////////////////////////////////

COLOURS

$primary: #4287f5
$lightgray: #e1e4e8


////////////////////////////////////////////////////////////////////////////////

DEFAULTS

$width: 700px
$fullpadding: 30px
$halfpadding: 15px
$lightpadding: 10px


////////////////////////////////////////////////////////////////////////////////

STRUCTURE

.container
  width: 100%
  max-width: $width
  padding: $fullpadding

.fullwidth
  width: 100%

.centeredcontent
  width: 100%
  display: flex
  justify-content: center

.columns
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center

.gap10
  gap: 10px

.fullwidthgap
  gap: $fullpadding

.card
  border: 1px solid #cccccc
  padding: 20px
  border-radius: 5px
  margin: 20px 0
  box-shadow: 0px 0px 5px 0px #cccccc


////////////////////////////////////////////////////////////////////////////////
TEXT

h1, h2, h3, h4, h5, p
  margin-bottom: $halfpadding


////////////////////////////////////////////////////////////////////////////////
BUTTONS

.button
  padding: $lightpadding $halfpadding
  font-size: 14px
  line-height: 1
  border-radius: 5px
  display: inline-block
  text-decoration: none
  cursor: pointer
  outline: none
  border: none
  background-color: #cccccc

  &.primary
    background-color: $primary
    color: #ffffff

  &:hover
    opacity: 0.9

  &.mini
    padding: 5px 8px

////////////////////////////////////////////////////////////////////////////////
MODAL

.modal
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: rgb(0,0,0)
  background-color: rgba(0,0,0,0.4)
  display: flex
  justify-content: center
  align-items: center

  .modalInner
    background-color: #ffffff
    width: 100%
    max-width: $width
    margin: 15%
    border-radius: 10px
    padding: 20px

