
@import '../../../../styles/theme.sass'

.behaviourTable

  p
    padding:0
    margin: 0

  .columns
    input
      width: 80%
      padding: 10px
      border-radius: 5px
      border: 1px solid #cccccc
      outline: none

    input, p
      margin: 0 0 10px 0

  .columns
    align-items: flex-start

.savingPopup
  display: flex
  align-items: center
  justify-content: center
  gap: 20px

  img
    width: 40px

  p
    margin: 0