
@import '../../../styles/theme.sass'

.header
  background-color: #000000

  p
    color: #ffffff
    font-size: 13px
    margin: 0

  img
    width: 200px

  .headerColumns
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center


    .loggedIn
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: flex-start
      gap: 10px

    .switchOrg
      margin: 10px 0 0 0
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: stretch
      gap: 10px

