@import '../styles/theme.sass'

.app
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between

  .content
    width: 100%
    display: flex
    flex-direction: column
    align-items: center